import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Input,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
  MessageParagraph,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Fuel Consumption Calculator",
    link: "/fuel-consumption-calculator/",
  },
]
const seeAlsoArray = [
  "/speed-and-fuel-economy-converter",
  "/length-units-converter",
  "/force-per-length-units-converter",
]

function FuelConsumptionCalculator(props) {
  let [ConsumptionInner, setConsumptionInner] = useState("")

  let distance = useRef("")
  let fuel = useRef("")
  let costper = useRef("")
  function computeConsumption() {
    let Xdistance = distance.current.value
    let Xfuel = fuel.current.value
    let Xcostper = costper.current.value

    var consumed = (Xfuel / (Xdistance / 100)).toFixed(2)
    var costper100 = (consumed * Xcostper).toFixed(2)
    setConsumptionInner(
      <span>
        Consumption is {consumed} Litres/100km ( $ {costper100} / 100km ) <br />
        <br />
        Total Fuel cost = $ {(Xfuel * Xcostper).toFixed(2)}
      </span>
    )
    if (Xdistance === "") {
      setConsumptionInner(<strong>pls enter Distance Driven .</strong>)
    } else if (Xfuel === "") {
      setConsumptionInner(<strong>pls enter Fuel Consumed .</strong>)
    } else if (Xcostper === "") {
      setConsumptionInner(
        <strong>pls enter the Cost per Unit of Fuel $.</strong>
      )
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Fuel Consumption Calculator"
        description="Calculate Fuel consumption (amount of fuel used per unit distance), Fuel consumption is the amount of fuel consumed in driving a given distance. [liters per 100 kilometers (L/100 km)]."
        keywords={[
          "Fuel Consumption, Fuel Consumption calculator, amount of fuel used per unit distance, amount fuel,amount of fuel used per unit distance.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Fuel Consumption Calculator</Title>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Distance Driven</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  ref={distance}
                  type="number"
                  min="150"
                  max="1000000"
                  onKeyUp={computeConsumption}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  km
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Fuel Consumed</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  ref={fuel}
                  type="number"
                  min="10"
                  max="10000000"
                  step="1"
                  onKeyUp={computeConsumption}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  L
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Cost per Unit of Fuel</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  ref={costper}
                  type="number"
                  min="1.49"
                  max="10"
                  step="0.01"
                  defaultValue="1.49"
                  onKeyUp={computeConsumption}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  $
                </StaticButton>
              </Control>
            </Field>

            <MessageParagraph>{ConsumptionInner}</MessageParagraph>

            {/* <div id="myerror"></div> */}
          </Column>
          <Column>
            <FlexDiv maxWidth="400px" margin="auto">
              <SmallImg filename="fuel.png" alt="fuel consumption calculator" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <h3>Fuel Consumption</h3>
        <p>
          Fuel consumption is the quantity of fuel used per unit length, (liters
          per 100 kilometers (L/100 km)).
        </p>

        <h3>Fuel Consumption Formula</h3>
        <p>
          Divide the liters it took to refill the tank by the distance traveled
          and multiply by 100.
          <br />
          <strong>
            example: 60 litres / 800 km x 100 = 7.5 litres per 100km (expressed
            7.5L/100km).
          </strong>
          <br />
          To Calculate Kilometres per liter fuel economy? <br />
          Divide the kilometers traveled by the number of liters it took to
          refill the tank.
          <br />
          <strong>example: 800km / 60 litres = 13.3km per litre</strong>
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default FuelConsumptionCalculator
